import React, {useEffect} from 'react'
import Layout from '../components/layout'

// markup
const AuthCallback = () => {

  useEffect(() => {
    // try to match an access token in window.location.hash
    if (typeof window !== "undefined"){
      var match = (window.location.hash) ? window.location.hash.match(/#access_token=([^&]+)/) : false;
      // if we found an access token in the URL pass the token up to a global function in
      if(match && match[1]) {
        var parentWindow = (window.opener && window.opener.parent) ? window.opener.parent : window
        parentWindow.oauthCallback(match[1]);
      }
      window.close();
    }
  }, [])

  return (
    <Layout>
      <main>
        
      </main>
    </Layout>
  )
}

export default AuthCallback
